import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-radio',
  styleUrl: 'x-radio.sass',
})
export class XRadio {
  @Prop() name: string;
  @Prop() value: string;
  @Prop() checked: boolean;
  @Prop() disabled: boolean;

  render() {
    return (
      <Host class="x-radio">
        <input type="radio" class="x-radio__input" name={this.name} value={this.value} checked={this.checked} disabled={this.disabled} />
        <span class="x-radio__box"></span>
      </Host>
    );
  }
}
