.x-radio
  position: relative
  &__input
    position: absolute
    top: 0
    left: 0
    width: 0
    height: 0
    z-index: -1
    opacity: 0.01
  &__box
    display: block
    border-radius: 50%
    width: 16px
    height: 16px
    box-sizing: border-box
    border: 2px solid var(--check-radio-static)
  &__input:checked ~ &__box
    border-width: 4px
    border-color: var(--check-radio-active)
  &__input[disabled] ~ &__box
    border-color: var(--check-radio-disabled)
